export const sortOptions = [
  {
    id: 'author',
    label: 'Author'
  },
  {
    id: 'title',
    label: 'Title'
  },
  {
    id: 'genre',
    label: 'Genre'
  },
  {
    id: 'date',
    label: 'Date'
  },
  {
    id: 'assetId',
    label: 'asset Id'
  },
  {
    id: 'isbn',
    label: 'ISBN'
  }
]

export const filterOptions = [
  {
    id: 'author',
    label: 'Author'
  },
  {
    id: 'title',
    label: 'Title'
  },
  {
    id: 'genre',
    label: 'Genre'
  },
  {
    id: 'isbn',
    label: 'ISBN'
  }
]
