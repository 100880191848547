import React from 'react'
import { useDropzone } from 'react-dropzone'
import Styles from './styles.module.sass'
import { BsCloudUpload } from 'react-icons/bs'

const DragAndDropFile = ({ onDrop, accept }) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  })

  return (
    <div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        { isDragActive
          ? <div className="dropzone-content">
              <div className={`file ${Styles.dragDropContainer}`}>
                <div className={Styles.iconContainer}>
                <BsCloudUpload size="50"/>
                </div>
                <div className={Styles.txtContainer}>
                <p className="has-text-weight-semibold">Click or drag file here to upload</p>
                <p className="is-size-7">Choose a file to upload your asset. App supports MD file type.</p>
                </div>
              </div>
            </div>
          : <div className="dropzone-content">
              <div className={`file ${Styles.dragDropContainer}`}>
                <div className={Styles.iconContainer}>
                <BsCloudUpload size="50"/>
                </div>
                <div className={Styles.txtContainer}>
                <p className="has-text-weight-semibold">Click or drag file here to upload</p>
                <p className="is-size-7">Choose a file to upload your asset. App supports MD file type.</p>
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default DragAndDropFile
