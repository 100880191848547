import { DropdownMenu } from '@marcelorodcla/pds-ui'
import React from 'react'
import Button from '../Button'
import GeneralStyles from '../../styles.module.sass'
import { createId } from '@/utils/general'
import { Link, useSearchParams } from 'react-router-dom'
import {
  setUrlWithParams
} from '@/utils/url'

const SortItemsMenu = ({
  sortOptions,
  currentItem,
  baseUrl
}) => {
  const [searchParams] = useSearchParams()
  const currentSearchParams = Object.fromEntries(searchParams)
  const itemClass = GeneralStyles.itemMenu
  const currentSortOptions = sortOptions.map(item => {
    const url = setUrlWithParams(
      baseUrl,
      {
        ...currentSearchParams,
        sortBy: item.id,
        sortOrder: 'asc'
      }
    )
    const isCurrent = currentItem === item.id
      ? GeneralStyles.active
      : ''
    const linkStyles = `${GeneralStyles.link} ${isCurrent}`

    return (
      <DropdownMenu.ItemMenu
        key={createId()}
        className={itemClass}
      >
        <Link to={url} className={linkStyles}>
          { item.label }
        </Link>
      </DropdownMenu.ItemMenu>
    )
  })

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        as="span"
      >
        <Button
           icon="FaSort"
           label="Sort by"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Menu>
        { currentSortOptions }
      </DropdownMenu.Menu>
    </DropdownMenu>
  )
}

export default SortItemsMenu
