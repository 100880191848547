import React, { useState } from 'react'
import { Input, Button } from '@marcelorodcla/pds-ui'
import Styles from './styles.module.sass'
import { useForm } from 'react-hook-form'
import {
  setUrlWithParams
} from '@/utils/url'
import { useSearchParams } from 'react-router-dom'

const SearchBar = ({
  currentFilterText,
  baseUrl,
  filter
}) => {
  const [error, setError] = useState()
  const [searchParams] = useSearchParams()
  const currentSearchParams = Object.fromEntries(searchParams)
  const {
    register,
    handleSubmit
  } = useForm({
    defaultValues: {
      filterText: currentFilterText
    }
  })

  const onSubmit = ({ filterText }) => {
    if (!filter) {
      setError('Set filter')
    } else if (!filterText) {
      setError('Write text to filter')
    }

    if (filter && filterText) {
      const currentUrl = setUrlWithParams(
        baseUrl,
        {
          ...currentSearchParams,
          filterBy: filter,
          filterText
        }
      )
      location.assign(currentUrl)
    }
  }

  return (
    <section>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex"
      >
        <Input
          className="mr-xs"
          { ...register('filterText')}
        />
        <Button
          className={Styles.button}
          type="submit"
        >
          Filter
        </Button>
      </form>
      { error &&
        <div className={Styles.error}>{ error }</div>
      }
    </section>
  )
}

export default SearchBar
